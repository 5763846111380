import React from 'react';
import Layout from '../components/layout';

const Success = () => {
  return (
    <Layout>
      <div className='uk-cover-container'>
        <div className='uk-section uk-section-large uk-background-default'>
          <div className='uk-container uk-container-xsmall uk-position-relative uk-text-center'>
            <div
              data-uk-scrollspy='cls: uk-animation-slide-bottom-medium; repeat: true'
              className='uk-scrollspy-inview uk-animation-slide-bottom-medium'
            >
              <h3 className='uk-margin-remove-top section-title-medium'>
                <div>Thank you for contacting</div>
                <div>
                  <mark className='mark'>Ample Maintenance!</mark>
                </div>
              </h3>
              <p className='uk-text-lead-xl'>
                We will contact you as soon as possible regarding your inquiry.
              </p>
              <p className='uk-text-lead-large'>
                <div>Need an immediate response?</div>
                <div>
                  <strong>
                    Call us at: <mark className='mark'>(631) 496-0164</mark>
                  </strong>
                </div>
              </p>
              <p className='uk-text-lead'>
                We look forward to working with you!
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Success;
